module.exports = [{
      plugin: require('/tmp/3e9a0424/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/3e9a0424/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null,null]},
    },{
      plugin: require('/tmp/3e9a0424/node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"YFORTJIX"},
    }]
