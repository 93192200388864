import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "-blocks"
    }}>{`📚 Blocks`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Empowering authors to write dynamic, engaging, and immersive content without leaving
their editor`}</p>
    </blockquote>
    <p>{`Blocks is a rich text editor with all the capabilities of components thanks to `}<a parentName="p" {...{
        "href": "https://mdxjs.com/"
      }}>{`MDX`}</a>{`.
It's a powerful WYSIWYG (what you see is what you get) built for the content web. You can
choose a Block from an ever-expanding library or even install your own from `}<a parentName="p" {...{
        "href": "https://npmjs.com/"
      }}>{`npm`}</a>{`.`}</p>
    <h2 {...{
      "id": "what-is-a-block"
    }}>{`What is a Block?`}</h2>
    <p>{`A Block refers to a piece of content or a component. It's a section of your content while
a document is a collection of blocks.`}</p>
    <p>{`Blocks can be simple like a paragraph of text or even a box with a tomato background color.
Blocks can be complex like an embedded spreadsheet or a chart that fetches live data.`}</p>
    <p>{`Blocks provides a UI for you to drop in and modify a Block. However, underneath the covers
it's JSX:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`# Below is a YouTube video

<Youtube id="1234" />

And a GitHub Gist:

<Gist id="5678" />
`}</code></pre>
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`📸`}<strong parentName="li">{`Customizable`}</strong>{`: Render your own components, or choose your favorite theme`}</li>
      <li parentName="ul">{`📨`}<strong parentName="li">{`Open and `}<a parentName="strong" {...{
            "href": "https://johno.com/authorable"
          }}>{`authorable`}</a></strong>{`: Underneath it's `}<a parentName="li" {...{
          "href": "https://mdxjs.com/"
        }}>{`MDX`}</a>{`, not a JSON schema or HTML`}</li>
      <li parentName="ul">{`🔐`}<strong parentName="li">{`Zero lock-in`}</strong>{`: You own your content, Blocks just makes it nicer to edit`}</li>
      <li parentName="ul">{`🖼`}<strong parentName="li">{`WYSIWYG`}</strong>{`: See what will be published as you edit`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      