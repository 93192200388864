"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _core = require("@emotion/core");

var _reactTwitterEmbed = require("react-twitter-embed");

var _isUrl = _interopRequireDefault(require("is-url"));

var _urlParse = _interopRequireDefault(require("url-parse"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var Tweet = function Tweet(_ref) {
  var tweetId = _ref.tweetId,
      props = _objectWithoutProperties(_ref, ["tweetId"]);

  return (0, _core.jsx)("div", null, tweetId ? (0, _core.jsx)(_reactTwitterEmbed.TwitterTweetEmbed, _extends({}, props, {
    options: {
      width: '100%'
    }
  })) : (0, _core.jsx)("pre", null, "Enter a Tweet ID"));
};

var getIDFromURL = function getIDFromURL(url) {
  var _parse = (0, _urlParse["default"])(url),
      pathname = _parse.pathname;

  var paths = pathname.split('/');
  var id = paths[paths.length - 1];
  return id;
};

Tweet.propertyControls = {
  isVoid: true,
  tweetId: {
    type: 'string',
    title: 'Tweet ID',
    formatValue: function formatValue(n) {
      return (0, _isUrl["default"])(n) ? getIDFromURL(n) : n;
    }
  }
};
var _default = Tweet;
exports["default"] = _default;