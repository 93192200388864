"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _core = require("@emotion/core");

var _adaptiveGradient = _interopRequireDefault(require("adaptive-gradient"));

var _randomHexColor = _interopRequireDefault(require("random-hex-color"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var TextGradient = function TextGradient(_ref) {
  var _ref$start = _ref.start,
      start = _ref$start === void 0 ? 'rebeccapurple' : _ref$start,
      end = _ref.end,
      _ref$stop = _ref.stop,
      stop = _ref$stop === void 0 ? '60%' : _ref$stop,
      _ref$angle = _ref.angle,
      angle = _ref$angle === void 0 ? 90 : _ref$angle,
      random = _ref.random,
      props = _objectWithoutProperties(_ref, ["start", "end", "stop", "angle", "random"]);

  if (random) {
    start = (0, _randomHexColor["default"])();
  }

  var gradient = (0, _adaptiveGradient["default"])(start);
  var gradientEnd = '#' + gradient.end;
  var backgroundGradient = "\n    linear-gradient(\n      ".concat(angle, "deg,\n      ").concat(start, ",\n      ").concat(end || gradientEnd, " ").concat(stop, "\n    )\n  ");
  return (0, _core.jsx)("div", _extends({
    css: {
      backgroundColor: start,
      background: backgroundGradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    }
  }, props));
};

TextGradient.propertyControls = {
  isVoid: false,
  start: {
    type: 'color'
  },
  end: {
    type: 'color'
  },
  stop: {
    type: 'string'
  },
  angle: {
    type: 'string'
  },
  random: {
    type: 'boolean'
  }
};
var _default = TextGradient;
exports["default"] = _default;