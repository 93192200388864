"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _core = require("@emotion/core");

var _reactYoutube = _interopRequireDefault(require("react-youtube"));

var _isUrl = _interopRequireDefault(require("is-url"));

var _getYoutubeId = _interopRequireDefault(require("get-youtube-id"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var formatValue = function formatValue(n) {
  return (0, _isUrl["default"])(n) ? (0, _getYoutubeId["default"])(n) : n;
};

var Wrapper = function Wrapper(props) {
  return (0, _core.jsx)("div", _extends({}, props, {
    css: {
      width: '100%',
      height: 0,
      paddingBottom: 900 / 16 + '%',
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: '#eee',
      iframe: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        border: 0
      }
    }
  }));
};

var YouTube = function YouTube(_ref) {
  var videoId = _ref.videoId,
      props = _objectWithoutProperties(_ref, ["videoId"]);

  return (0, _core.jsx)(Wrapper, null, (0, _core.jsx)(_reactYoutube["default"], _extends({
    videoId: formatValue(videoId)
  }, props)));
}; // mimicking Framer X - could make this compatible


YouTube.propertyControls = {
  isVoid: true,
  videoId: {
    type: 'string',
    title: 'Video ID',
    description: 'YouTube ID of the video',
    formatValue: formatValue
  }
};
var _default = YouTube;
exports["default"] = _default;